import React from "react";
import {Routes, Route} from "react-router-dom";
import {Provider} from "mobx-react";
//local
import "./App.css";
import store from "./store/index";
import {
  ADMIN,
  ADMIN_TRANSLATIONS,
  ADMIN_LOGIN,
  ADMIN_RESET_PASS,
  ADMIN_RESET_PASS_DONE,
  ADMIN_CHANGE_PASS,
  ADMIN_CHANGE_PASS_DONE,
  ADMIN_REGISTER,
  USERS,
  USER,
  SUBSCRIPTION_ORDERS,
  ADVERTISEMENTS,
  PERMISSIONS,
  ORGANIZATION,
  ORGANIZATIONS,
  COMPANIES,
  COMPANY,
  APPLYING_USER,
  ORGSNOBANKID,
  COMMON_SETTINGS,
  CREATE_ANY_COMPANY,
  OPEN_RFPS,
} from "./constants/routes.const";

import {AdminRoute} from "./router/AdminRoute";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import OpenRFPs from "./pages/OpenRFPs";
import AdminTranslationsPage from "./pages/AdminTranslationsPage/AdminTranslationsPage";
import AdminResetPassDone from "./pages/AdminAuthPages/AdminResetPassDone/AdminResetPassDone";
import AdminChangePassDone from "./pages/AdminAuthPages/AdminChangePassDone/AdminChangePassDone";
//Admin-forms-pages
import AdminLoginPage from "./pages/AdminAuthPages/AdminLoginPage/AdminLoginPage";
import AdminResetPassPage from "./pages/AdminAuthPages/AdminResetPassPage/AdminResetPassPage";
import AdminChangePassPage from "./pages/AdminAuthPages/AdminChangePassPage/AdminChangePassPage";
import AdminRegisterPage from "./pages/AdminAuthPages/AdminRegisterPage/AdminRegisterPage";
import CommonSettingsPage from "./pages/CommonSettings/CommonSettingsPage";
import UsersPage from "./pages/users/UsersPage";
import UserPage from "./pages/users/UserPage";
import ApplyingUsersPage from "./pages/ApplyingUsers/ApplyingUsersPage";
import OrgsNoBankid from "./pages/OrgsNoBankid";
import OrganizationsPage from "./pages/organizations/OrganizationsPage";
import OrganizationPage from "./pages/organizations/OrganizationPage";
import SubscriptionsOrdersPage from "./pages/subscriptions/SubscriptionsOrdersPage";
import AdvertisementsPage from "./pages/Advertisements/AdvertisementsPage";
import CompanyPage from "./pages/companies/CompanyPage";
import CompaniesPage from "./pages/companies/CompaniesPage";
import PermissionsPage from "./pages/permissions/PermissionsPage";
import CreateForeignCompany from "./pages/CreateForeignCompany";

(async () => {
  await store.admin.fetchAdminUserInfo();
  await store.admin.fetchSubscriptionsInfo();
})();

const routes = () => {
  return (
    <Provider store={store}>
      <Routes>
        <Route exact path={ADMIN} element={<AdminRoute Component={AdminDashboard} />} />
        <Route exact path={OPEN_RFPS} element={<AdminRoute Component={OpenRFPs} />} />
        <Route exact path={USERS} element={<AdminRoute Component={UsersPage} />} />
        <Route exact path={USER} element={<AdminRoute Component={UserPage} />} />
        <Route
          exact
          path={CREATE_ANY_COMPANY}
          element={<AdminRoute Component={CreateForeignCompany} />}
        />
        <Route
          exact
          path={APPLYING_USER}
          element={<AdminRoute Component={ApplyingUsersPage} />}
        />
        <Route
          exact
          path={ORGANIZATIONS}
          element={<AdminRoute Component={OrganizationsPage} />}
        />
        <Route
          exact
          path={ORGANIZATION}
          element={<AdminRoute Component={OrganizationPage} />}
        />
        <Route
          exact
          path={COMPANIES}
          element={<AdminRoute Component={CompaniesPage} />}
        />
        <Route exact path={COMPANY} element={<AdminRoute Component={CompanyPage} />} />
        <Route
          exact
          path={ORGSNOBANKID}
          element={<AdminRoute Component={OrgsNoBankid} />}
        />
        <Route
          exact
          path={SUBSCRIPTION_ORDERS}
          element={<AdminRoute Component={SubscriptionsOrdersPage} />}
        />
        <Route
          exact
          path={PERMISSIONS}
          element={<AdminRoute Component={PermissionsPage} />}
        />
        <Route
          exact
          path={ADVERTISEMENTS}
          element={<AdminRoute Component={AdvertisementsPage} />}
        />
        <Route
          exact
          path={ADMIN_TRANSLATIONS}
          element={<AdminRoute Component={AdminTranslationsPage} />}
        />
        <Route
          exact
          path={COMMON_SETTINGS}
          element={<AdminRoute Component={CommonSettingsPage} />}
        />

        <Route exact path={ADMIN_LOGIN} element={<AdminLoginPage />} />
        <Route exact path={ADMIN_RESET_PASS} element={<AdminResetPassPage />} />
        <Route exact path={ADMIN_RESET_PASS_DONE} element={<AdminResetPassDone />} />
        <Route exact path={ADMIN_CHANGE_PASS} element={<AdminChangePassPage />} />
        <Route exact path={ADMIN_CHANGE_PASS_DONE} element={<AdminChangePassDone />} />
        <Route exact path={ADMIN_REGISTER} element={<AdminRegisterPage />} />
        <Route
          path="*"
          element={
            <div className="error-page_title">
              <h2>Page does not exist</h2>
              <h3>404</h3>
            </div>
          }
        />
      </Routes>
    </Provider>
  );
};

export default routes;
