import {notification} from "antd";

export const showError = (text, duration, message, status) => {
  notification[status || "error"]({
    message: message || "Error",
    duration: duration || 4.5,
    description: text || "Something went wrong!",
  });
};

export const showWarning = (text, duration, message, status) => {
  notification[status || "warning"]({
    message: message || "Warning",
    duration: duration || 4.5,
    description: text || "Something went wrong!",
  });
};

export const showSuccess = (text, duration, message, status) => {
  notification[status || "success"]({
    message: message || "Success",
    duration: duration || 4,
    description: text || "Updated successfully!",
  });
};

export const showInfo = (text, duration, message, status) => {
  notification[status || "info"]({
    message: message || "Info",
    duration: duration || 4,
    description: text || "Info",
  });
};
