import React, {useState, useMemo} from "react";
import {Button, Select, Input, Radio, Tag} from "antd";
import {subscriptionPricePeriods} from "constants/user.consts";
import {useTranslation} from "react-i18next";

export const Subscription = ({
  activeSubscriptions,
  subscriptions = [],
  submitSubscription,
  submitOnboardingAC,
  email,
  users,
  reference,
}) => {
  const {Option} = Select;
  const [currentSubscriptionsIds, setCurrentSubscriptionsIds] = useState(
    activeSubscriptions.map((subscription) => subscription?.id)
  );
  const [currentEmail, setCurrentEmail] = useState(email);
  const [currentReference, setCurrentReference] = useState(reference);

  const [currentSubscriptionAutomationAC, setCurrentSubscriptionAutomationAC] =
    useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const {t} = useTranslation();

  const periodOptions = subscriptions?.reduce(
    (obj, sub) =>
      Object.assign(obj, {
        [sub.id]: subscriptionPricePeriods
          .filter((item) => sub[item.priceKey] !== null)
          .map((item) => ({
            value: item.periodKey,
            label: t(`ADMIN_ORGANIZATION_SUBSCRIPTION_${item.periodKey}`),
          })),
      }),
    {}
  );
  const periods = useMemo(
    () =>
      subscriptions?.reduce(
        (obj, item) => Object.assign(obj, {[item.id]: item.pricePeriodDefault}),
        {}
      ),
    [subscriptions]
  );
  const activePeriods = useMemo(
    () =>
      activeSubscriptions?.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.SubscribeUnitProduct?.pricePeriod ?? item.pricePeriodDefault,
          }),
        {}
      ),
    [activeSubscriptions]
  );
  const [periodValues, setPeriodValues] = useState({...periods, ...activePeriods});

  const onChangeSubscriptionPeriod = (target, subscriptionId) =>
    setPeriodValues((prevState) => ({...prevState, [subscriptionId]: target.value}));

  const tagRender = (props) => {
    const {label, value, closable, onClose} = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{marginInlineEnd: 4, border: "1px solid #f0f0f0", fontSize: "14px"}}
      >
        {label}
        {periodOptions[value]?.length > 1 ? (
          <Radio.Group
            options={periodOptions[value]}
            onChange={({target}) => onChangeSubscriptionPeriod(target, value)}
            value={periodValues[value]}
            className="ml-2"
          />
        ) : (
          ` (${periodOptions[value]?.[0].label})`
        )}
      </Tag>
    );
  };

  return (
    <>
      <div className="df-row-center">
        <Select
          className="mr-4"
          mode="multiple"
          style={{width: "50%"}}
          defaultValue={currentSubscriptionsIds}
          onChange={setCurrentSubscriptionsIds}
          tagRender={tagRender}
          key={"id"}
        >
          {!!subscriptions?.length &&
            subscriptions.map((item) => (
              <Option key={item.id} value={item.id}>
                {t(item.name)}
              </Option>
            ))}
        </Select>
        <Input
          placeholder="Invoice email"
          value={currentEmail}
          onChange={(e) => setCurrentEmail(e.target.value)}
          style={{width: "20%"}}
          className="mr-2"
        />
        <Input
          placeholder="Invoice reference"
          value={currentReference}
          onChange={(e) => setCurrentReference(e.target.value)}
          style={{width: "15%"}}
          className="mr-4"
        />
        <Button
          type="primary"
          onClick={() =>
            submitSubscription({
              subscribeProducts: currentSubscriptionsIds.sort().map((key) => ({
                subscribeProductId: key,
                pricePeriod: periodValues[key],
              })),
              paymentEmail: currentEmail,
              invoiceReference: currentReference,
            })
          }
        >
          Set subscription
        </Button>
      </div>
      <div className="df-row-center mt-3">
        <div className="df-column-left">
          <span className="__bold">Add org users to AC automation</span>
          <p className="mr-4">
            This will add org users to AC automation as if they purchased online. Use this
            when you've added a subscription here but want to send them the onboarding
            sequence in Active Campaign.
          </p>
        </div>
        <Select
          className="mla"
          style={{width: "24%"}}
          placeholder="Which subscription did they buy?"
          value={currentSubscriptionAutomationAC}
          onChange={setCurrentSubscriptionAutomationAC}
          key={"id"}
        >
          {!!subscriptions?.length &&
            subscriptions.map((item) => (
              <Option key={item.id} value={item.id}>
                {t(item.name)}
              </Option>
            ))}
        </Select>
        <Select
          key={"id"}
          className="ml-4 mr-4"
          style={{width: "24%"}}
          placeholder="Which user made the purchase?"
          value={currentUser}
          onChange={setCurrentUser}
        >
          {users?.map((user) => (
            <Option key={user.id} value={user.id}>
              {`${user.firstName} ${user.lastName}`}
            </Option>
          ))}
        </Select>
        <Button
          type="primary"
          onClick={() => {
            submitOnboardingAC({
              subscribeProductId: currentSubscriptionAutomationAC,
              userId: currentUser,
            });
            setCurrentSubscriptionAutomationAC(null);
            setCurrentUser(null);
          }}
          disabled={!(currentUser && currentSubscriptionAutomationAC)}
        >
          Add
        </Button>
      </div>
    </>
  );
};
