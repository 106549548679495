import {makeObservable, observable, action} from "mobx";
import axiosInstance from "../axios";
import {showSuccess} from "../helpers/notifications.helper";
import {setStorage, getStorage} from "../helpers/storage";
import {ADMIN_TOKEN} from "../constants/admin.consts";

class AdminStore {
  constructor() {
    this.registerStep = 0;
    this.adminUser = null;
    this.adminUserInfo = {};
    this.subscriptions = null;
    this.subscriptionRoles = null;
    this.token = getStorage(ADMIN_TOKEN);
    this.isAddNewTransFormOpen = false;
    this.loadingTranslations = true;
    this.translationFilterParams = {
      search: "",
      order: "default",
    };
    this.translationData = {
      translations: [],
    };
    this.btnLoading = false;
    makeObservable(this, {
      btnLoading: observable,
      registerStep: observable,
      adminUser: observable,
      adminUserInfo: observable,
      token: observable,
      isAddNewTransFormOpen: observable,
      loadingTranslations: observable,
      translationData: observable,
      setLoadingTranslations: action,
      getTranslations: action,
      addNewTranslationElement: action,
      editTranslationElement: action,
      updateTranslationData: action,
      deleteTranslationElement: action,
      setTranslationData: action,
      asignAdminToken: action,
      fetchAdminUserInfo: action,
      asignAdminUserInfo: action,
      fetchSubscriptionsInfo: action,
      asignSubscriptions: action,
      asignSubscriptionRoles: action,
    });
  }

  async fetchAdminUserInfo() {
    const {data: adminData} = await axiosInstance.get("admin/admin-user-info");
    this.asignAdminUserInfo(adminData);
  }

  asignAdminUserInfo(adminUser) {
    this.adminUserInfo = {...adminUser};
  }

  async fetchSubscriptionInfo() {
    const {data} = await axiosInstance.get("admin/subscribes/products");
    this.asignSubscriptions(data);
  }

  async fetchSubscriptionRolesInfo() {
    const {data} = await axiosInstance.get("admin/subscribes/roles");
    this.asignSubscriptionRoles(data);
  }

  async fetchSubscriptionsInfo() {
    await Promise.all([this.fetchSubscriptionInfo(), this.fetchSubscriptionRolesInfo()]);
  }

  asignSubscriptions(subscriptions) {
    this.subscriptions = [...subscriptions];
  }
  asignSubscriptionRoles(subscriptionRoles) {
    this.subscriptionRoles = [...subscriptionRoles];
  }

  setRegisterStep(value) {
    this.registerStep = value;
  }

  sendAdminVerificationCode(values) {
    return axiosInstance.post("admin/send-register-code", values).then(({data}) => {
      this.adminUserInfo.request_id = data?.response?.request_id;
      this.adminUserInfo.mobilePhone = values?.mobilePhone;
    });
  }

  adminCheckSmsCode(code) {
    const data = {
      request_id: this.adminUserInfo.request_id,
      mobilePhone: this.adminUserInfo.mobilePhone,
      code,
    };
    return axiosInstance.post("admin/check-register-code", data).then(({data}) => {
      this.adminUserInfo.registerToken = data?.registerToken;
      showSuccess(data.message);
    });
  }

  sendAdminRegistrationInfo(values) {
    const data = {
      ...values,
      mobilePhone: this.adminUserInfo.mobilePhone,
      token: this.adminUserInfo.registerToken,
    };
    return axiosInstance.post("admin/registration", data);
  }

  async checkAdminLogin(values) {
    const {data} = await axiosInstance.post("admin/login", values);
    setStorage(ADMIN_TOKEN, data.token);
    this.asignAdminToken(data.token);
  }

  asignAdminToken(token) {
    this.token = token;
  }

  recoverAdminPass(values) {
    return axiosInstance.post("admin/recover-password", values);
  }

  resetAdminPass(values) {
    return axiosInstance.post("admin/reset-password", values);
  }

  async getTranslations(page) {
    this.setLoadingTranslations(true);
    const params = {
      ...this.translationFilterParams,
      page: page || this.translationData.currentPage,
    };
    const {data} = await axiosInstance.get("admin/locales/translations", {params});
    this.setTranslationData(data);
    this.setLoadingTranslations(false);
  }

  setTranslationData(data) {
    this.translationData = data;
  }

  setLoadingTranslations(value) {
    this.loadingTranslations = value;
  }

  setOpenNewTranslationForm(value) {
    this.isAddNewTransFormOpen = value || !this.isAddNewTransFormOpen;
  }

  addNewTranslationElement(values) {
    const data = {
      ...values,
      key: values.key.toUpperCase(),
    };
    return axiosInstance.post("admin/locales/translations", data).then(({data}) => {
      this.translationData = {
        ...this.translationData,
        translations: [data.translation, ...this.translationData.translations],
      };
    });
  }

  editTranslationElement(values) {
    const transId = values.id;
    return axiosInstance
      .put(`admin/locales/translations/${transId}`, values)
      .then(({data}) => {
        this.updateTranslationData({
          translations: this.translationData.translations.map((item) => {
            if (item.id === data.translation.id) {
              return data.translation;
            }
            return item;
          }),
        });
      });
  }

  deleteTranslationElement(transId) {
    return axiosInstance.delete(`admin/locales/translations/${transId} `).then(() => {
      this.updateTranslationData({
        translations: this.translationData.translations.filter(
          (item) => item.id !== transId
        ),
      });
    });
  }

  updateTranslationData(data) {
    this.translationData = {
      ...this.translationData,
      ...data,
    };
  }

  setTranslationFilterParams(params) {
    this.translationFilterParams = params;
  }

  downloadTranslations() {
    return axiosInstance.get("admin/locales/translations/download");
  }

  uploadTranslations(file) {
    const data = new FormData();
    data.append("file", file);
    return axiosInstance.post("admin/locales/translations/upload", data).then(() => {
      this.getTranslations();
      showSuccess("Translation database updated");
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AdminStore();
